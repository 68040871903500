<template>
  <div class="container-fluid">
    <div
      v-if="data"
      class="card"
    >
      <div class="card-body">
        <div class="mb-2">
          <h3>
            จัดการออเดอร์
          </h3>
        </div>
        <div>
          <div class="row mt-2 mb-2">
            <div class="col-12 col-sm-6 mb-2">
              <div>
                <div class="d-flex">
                  <h5 style="width: 80px;">
                    รหัสออเดอร์:
                  </h5>
                  <h5 class="ml-1">
                    {{ data.code }}
                  </h5>
                </div>
                <div class="d-flex">
                  <h5 style="width: 80px;">
                    รหัสลูกค้า:
                  </h5>
                  <h5 class="ml-1">
                    {{ data.customerId.code }}
                  </h5>
                </div>
                <div class="d-flex">
                  <h5 style="width: 80px;">
                    ชื่อ:
                  </h5>
                  <h5 class="ml-1">
                    {{
                      `${data.customerId.firstName} ${data.customerId.lastName}`
                    }}
                  </h5>
                </div>
                <div class="d-flex">
                  <h5 style="width: 80px;">
                    เบอร์โทร:
                  </h5>
                  <h5 class="ml-1">
                    {{ `${data.customerId.telephoneNumber}` }}
                  </h5>
                </div>
                <div
                  v-if="data.address"
                  class="d-flex mb-auto"
                >
                  <h5 style="width: 80px;">
                    ที่อยู่:
                  </h5>
                  <h5 class="ml-1">
                    {{
                      `${data.address.address}  ${data.address.district}  ${data.address.city}  ${data.address.province} ${data.address.postal}`
                    }}
                  </h5>
                </div>
                <div v-if="data.dataPay">
                  <div
                    class="d-flex mt-2"
                  >
                    <h5 style="width: 80px;">
                      บัญชีร้าน:
                    </h5>
                    <h5 class="ml-1">

                      {{ ` ${data.dataPay.bankaccountId.bankName} ${data.dataPay.bankaccountId.bankNumber} ${data.dataPay.bankaccountId.name}` }}
                    </h5>
                  </div>
                  <div
                    v-if="data.dataPay.bankAccount"
                    class="d-flex"
                  >
                    <h5 style="width: 80px;">
                      บัญชีลูกค้า:
                    </h5>
                    <h5 class="ml-1">
                      {{ ` ${data.dataPay.bankAccount.bankName} ${data.dataPay.bankAccount.bankNumber} ${data.dataPay.bankAccount.name}` }}
                    </h5>
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <h5 style="width: 80px;">
                    สถานะ:
                  </h5>
                  <h5
                    class="ml-1"
                    :class="data.status === 'i'?'text-primary':data.status === 'c'?'text-danger':data.status === 'a'?'text-info':data.status === 's'?'text-warning':'text-success'"
                  >
                    {{ data.status === 'i'?'รอการจัดการ':data.status === 'c'?'ยกเลิก':data.status === 'a'?'รอการชำระ':data.status === 's'?'จัดส่งเรียบร้อย':'อนุมัติ' }}
                  </h5>
                </div>
                <div v-if="data.status === 's'">
                  <div class="d-flex">
                    <h5 style="width: 100px;">
                      ช่องทางจัดส่ง:
                    </h5>
                    <h5>
                      {{ data.transportBy }}
                    </h5>
                  </div>
                  <div class="d-flex">
                    <h5 style="width: 100px;">
                      เลขพัสดุ:
                    </h5>
                    <h5
                      class="text-primary"
                      style="cursor:pointer"
                      @click="onClickTacking(data.transportBy,data.transportNo)"
                    >
                      <u>{{ data.transportNo }}</u>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div
                v-if="data.dataPay"
                class="d-flex justify-content-center"
              >
                <LightGallery
                  :images="[{ title:'slip', url: $UrlImg+data.dataPay.pic }]"
                  :index="index"
                  :disable-scroll="true"
                  @close="index = null"
                />
                <b-img
                  v-for="(thumb, thumbIndex) in [
                    data.dataPay.pic,
                  ]"
                  :key="thumbIndex"
                  alt="Circle image"
                  :src="$UrlImg+data.dataPay.pic"
                  style="cursor:pointer;max-width:250px;width:100%"
                  @click="index = thumbIndex"
                  @error="setAltImg"
                />
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-12 col-sm-6 align-self-center">
              <h4 class="text-primary">
                {{ data.totalPrice.$numberDecimal | toCurrency }} {{ ` บาท` }}
              </h4>
            </div>
            <div class="col-12 col-sm-6 col-md-4 ">
              <b-form-group
                label="คลัง"
                label-for="vi-first-name"
              >
                <b-form-select
                  v-model="inputs.warehouseId"
                  :disabled="data.status !=='i'?true:false"
                  :options="opptionwhs"
                />
              </b-form-group>
            </div>
          </div>
          <b-modal
            id="modal-center"
            ref="my-modal"
            v-model="IsopenModalcancel"
            hide-footer
            centered
            header-bg-variant="primary"
            title="ยกเลิก"
          >
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group
                  label="คำอธิบาย"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="h-warehouse-name"
                    v-model="inputs.description"
                  />

                </b-form-group>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-end">
              <div class="d-grid d-md-block">
                <b-button
                  variant="primary"
                  class="btn mr-1"
                  type="button"
                  @click="onClickConfirmCancel"
                >
                  ยืนยัน
                </b-button>
                <b-button
                  class="btn btn-danger mr"
                  type="button"
                  @click="closeModalCancel"
                >
                  ปิด
                </b-button>
              </div>
            </div>
          </b-modal>

        </div>

        <b-table
          striped
          hover
          responsive
          class="type-relative"
          :items="listOrder"
          :fields="fields"
          show-empty
        >
          <template #cell(amount)="datas">
            <div class="d-flex justify-content-end">
              <p :class="parseInt(datas.item.amount.$numberDecimal) > parseInt(datas.item.amountWhs) && data.status ==='i'?'text-danger':''">
                {{ datas.item.amount.$numberDecimal | toAmount }}
              </p>
              <div
                v-if="data.status === 'i'"
                class="d-flex"
              >
                <p class="ml-1 mr-1">
                  {{ ` /  ` }}
                </p>
                <p>
                  {{ datas.item.amountWhs | toAmount }}
                </p>
              </div>

            </div>
          </template>
          <template #cell(price)="datas">
            <div v-if="datas.item.promotion> 0">
              <p class="font-weight-bolder">
                {{ [{price:datas.item.price.$numberDecimal,percen:datas.item.promotion}] | toPercen }} <b-badge variant="danger">
                  {{ `${datas.item.promotion} %` }}
                </b-badge>
              </p>
              <p style="margin-top:-5px">
                <s>{{ datas.item.price.$numberDecimal|toCurrency }}</s>
              </p>

            </div>
            <div v-else>
              <p>{{ datas.item.price.$numberDecimal|toCurrency }}</p>
            </div>
          </template>
          <template #cell(button)="datas">
            <div
              v-if="parseInt(datas.item.amount.$numberDecimal) > parseInt(datas.item.amountWhs) && data.status ==='i'"
              class="d-grid d-md-block"
            >
              <button
                class="btn btn-primary mr-1"
                type="button"
                @click="onClickProductAdd(datas.item.id.code)"
              >
                เพิ่ม
              </button>
              <button
                class="btn btn-info mr-1"
                type="button"
                @click="onClickProductTranfer(datas.item.id.code)"
              >
                โอน
              </button>
              <!-- <button
                class="btn btn-info mr-1"
                type="button"
                @click="getFile(data.item.item)"
              >
                รายงาน
              </button> -->
            </div>
          </template>
          <template #empty>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลการขาย</h4>
            </div>
          </template>
          <template #emptyfiltered>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลการขาย</h4>
            </div>
          </template>
        </b-table>
        <div class="mb-1 d-flex justify-content-end">
          <b-button
            variant="primary"
            class="mr-1"
            style="    width: 100px;"
            :disabled="btConfirm"
            @click="onClickConfirm"
          >
            ยืนยัน
          </b-button>
          <b-button
            variant="danger"
            class="mr-1"
            style="    width: 100px;"
            :disabled="data.status ==='c'?true:false"
            @click="onClickCancel()"
          >
            ยกเลิก
          </b-button>

        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow,
  BCol,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BModal,
  BButton,
  VBModal,
  BCardBody,
  BFormInput,
  BFormCheckbox,
  BBadge,
  BForm,
  BImg
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DatePicker from '@/views/components/production/datePicker.vue'
import PlaceHolder from '@/assets/images/production/img_default.png'
// import PlaceHolder from '@/assets/images/production/S__3178539.jpg'
import transport from '@/data-json/transport.json'

export default defineComponent({
  name: 'ordermanagement',

  components: {
    BRow,
    BCol,
    BTable,
    BButton,
    BFormSelect,
    BPagination,
    BCardBody,
    BFormGroup,
    BCardCode,
    BModal,
    VBModal,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    required,
    DatePicker,
    BFormCheckbox,
    BBadge,
    BImg
  },
  created () {
    this.loadWarehouse()
    this.inputs.id = this.$route.params.code
  },
  computed: {
    btConfirm () {
      const ck = this.listOrder.filter(x => parseInt(x.amount.$numberDecimal) > parseInt(x.amountWhs))
      // eslint-disable-next-line no-mixed-operators
      const st = this.data ? this.data.status : ''
      console.log(st)
      return !!(ck.length > 0 || (st !== 'i'))
    }

  },
  watch: {
    'inputs.warehouseId': {
      handler (val) {
        if (this.data) {
          this.getStock()
        }
      }
    }

  },
  methods: {
    onClickTacking (name, code) {
      const data = transport.RECORDS.filter(x => x.transportBy === name)
      if (data.length > 0) {
        window.open(`${data[0].transportLink}${code}`)
      }
    },
    onClickReturnPage () {
      this.$router.go(-1)
    },
    onClickConfirmCancel () {
      this.inputs.status = 'c'
      console.log(this.inputs)
      this.$store.dispatch('order/confirmCancelOrdermanagement', this.inputs).then(res => {
        if (res.data.success) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'success',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: res.data.message
            }
          })
          this.closeModalCancel()
          this.onClickReturnPage()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: res.data.message
            }
          })
        }
      })
    },
    onClickConfirm () {
      this.inputs.status = 'a'
      this.$store.dispatch('order/confirmOrdermanagement', this.inputs).then(result => {
        if (result.data.success) {
          this.inputs.status = 'n'
          this.$store.dispatch('order/confirmOrdermanagement', this.inputs).then(res => {
            if (res.data.success) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: res.data.message
                }
              })
              this.onClickReturnPage()
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'error',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: res.data.message
                }
              })
            }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: result.data.message
            }
          })
        }
      })
    },
    onClickCancel () {
      this.IsopenModalcancel = true
    },
    closeModalCancel () {
      this.IsopenModalcancel = false
    },
    loadWarehouse () {
      this.$store.dispatch('warehouse/getWareHouse')
        .then(response => {
          const dt = response.data.items
          dt.forEach((data, index) => {
            if (index === 0) this.inputs.warehouseId = data._id
            this.opptionwhs.push({ value: data._id, text: data.name })
          })
          this.loader()
        }).catch(error => {
          console.log(error)
        })
    },
    onClickProductAdd (val) {
      this.$router.push(`/product/add/${val}/add/${this.inputs.warehouseId}`)
    },
    onClickProductTranfer (val) {
      this.$router.push(`/product/tranfer/${val}/${this.inputs.warehouseId}`)
    },
    async loader () {
      const resOrder = await this.$store.dispatch('order/getOrderIDPay', { id: this.$route.params.code })
      const resPayment = await this.$store.dispatch('order/gettransaction', { orderId: this.$route.params.code })
      console.log('ssss', resPayment)
      this.data = { ...resOrder.data.items[0], dataPay: resPayment.data.item ? resPayment.data.item : null }
      console.log(this.data)
      this.fields[3].label = this.data.status !== 'i' ? 'จำนวน' : 'จำนวน/คงเหลือ'
      this.listOrder = []
      this.data.listProduct.forEach((data, index) => {
        this.listOrder.push({ ...data, amountWhs: 0 })
      })
      const checkWhs = this.listOrder.filter(x => x.warehouseId !== null)
      if (checkWhs.length > 0) {
        this.inputs.warehouseId = checkWhs[0].warehouseId
      } else {
        this.getStock()
      }
    },
    getStock () {
      const mapPD = this.listOrder.map(x => x.id._id)
      const inputs = {
        warehouseId: this.inputs.warehouseId,
        productId: mapPD
      }
      this.$store
        .dispatch('stock/getamount', inputs)
        .then(res => {
          // eslint-disable-next-line prefer-destructuring
          const dt = res.data.items
          this.listOrder.forEach((data, index) => {
            this.listOrder[index].amountWhs = 0
            const dtsult = dt.filter(x => x.product._id === data.id._id)
            if (dtsult.length > 0) {
              this.listOrder[index].amountWhs = parseInt(dtsult[0].amount.$numberDecimal)
            }
          })
        })
    },
    setAltImg (e) {
      console.log('qqqq', e)
      e.target.src = PlaceHolder
    }
  },
  data () {
    return {
      data: null,
      img: PlaceHolder,
      inputs: {
        id: '',
        status: '',
        warehouseId: '',
        description: ''
      },
      IsopenModalcancel: false,
      index: null,
      listOrder: [],
      opptionwhs: [],
      opption: [
        { value: '', text: 'ทั้งหมด' },
        { value: 'i', text: 'รอการจัดการ' },
        { value: 'n', text: 'อนุมัติ' }
      ],
      fields: [
        {
          key: 'id.code',
          label: 'รหัสสินค้า',
          thStyle: 'min-width: 165px',
          sortable: true
        },
        {
          key: 'id.name',
          label: 'ชื่อสินค้า',
          thStyle: 'min-width: 200px'
        },
        {
          key: 'price',
          label: 'ราคา',
          thStyle: 'min-width: 180px',
          tdClass: 'text-right',
          thClass: 'text-right'
        },
        {
          key: 'amount',
          label: 'จำนวน/คงเหลือ',
          thStyle: 'min-width: 220px',
          tdClass: 'text-right',
          thClass: 'text-right'
        },
        {
          key: 'button',
          label: '',
          thStyle: 'min-width: 220px',
          tdClass: 'text-center',
          thClass: 'text-center'
        }
      ]
    }
  }
})
</script>
<style >
.modal-title {
  color: white;
}
</style>
